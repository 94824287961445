import { Form, Formik } from "formik";
import React from "react";
import { UseMutateFunction } from "react-query";

import { Heading } from "@source-web/heading";
import { Link } from "@source-web/link";
import { TextInputWithLabel } from "@source-web/text-input-with-label";

import { ContentfulSignInPage, FormikValuesType } from "..";
import { PrimaryButton } from "../../../components/PrimaryButton";
import * as Styled from "../styles/signIn.styles";
import { signInFormSchema } from "./signInFormSchema";

interface SignInFormProps {
  onSignIn: UseMutateFunction<
    FormikValuesType,
    unknown,
    FormikValuesType,
    FormikValuesType
  >;

  hideErrorMessage: () => void;
  isError: boolean;
  isLoading: boolean;
  data: ContentfulSignInPage["contentfulSignInPage"];
  errorMessage?: string | null;
}

export function SignInForm({
  data,
  onSignIn,
  isError,
  hideErrorMessage,
  errorMessage,
  isLoading
}: SignInFormProps) {
  return (
    <Formik
      validateOnChange
      validationSchema={signInFormSchema}
      initialValues={{ user: "", password: "" }}
      onSubmit={(values: FormikValuesType, actions) => {
        hideErrorMessage();
        onSignIn(values);
        actions.setSubmitting(false);
      }}
    >
      {({ handleChange, values, isSubmitting, touched, errors }) => (
        <Form>
          <Styled.FieldsWrapper data-testid="errorMessageContainer">
            {isError && errorMessage ? (
              <Styled.ErrorMessageContainer data-testid="errorMessageContainer-reason">
                {errorMessage && <div>{errorMessage}</div>}
              </Styled.ErrorMessageContainer>
            ) : null}
            {data.textFields.map((textField) => {
              const errorsById = (errors as Record<string, any>)[
                textField.fieldId
              ];
              const touchedById = (touched as Record<string, any>)[
                textField.fieldId
              ];
              const value = (values as Record<string, any>)[textField.fieldId];
              return (
                <>
                  <TextInputWithLabel
                    key={textField.fieldId}
                    state={touchedById && errorsById ? "error" : undefined}
                    fieldWrapper={{
                      label: textField.label,
                      width: "full",
                      helpText:
                        touchedById && errorsById
                          ? textField.helpText
                          : undefined
                    }}
                    textInput={{
                      type: textField.type,
                      placeholder: textField.placeholder,
                      id: textField.fieldId,
                      onChange: handleChange,
                      value
                    }}
                  />
                  <br />
                </>
              );
            })}

            <Styled.ButtonContainer>
              <PrimaryButton
                width="full"
                state={errors.user || errors.password ? "disabled" : undefined}
                text={data.buttonText}
                loading={isLoading}
                htmlAttributes={{ type: "submit" }}
                onClick={(e) => {
                  if (isSubmitting) {
                    e.preventDefault();
                  }
                }}
              />
            </Styled.ButtonContainer>
            <Styled.Trouble>
              <Heading size={1}> {data?.trouble} </Heading>
              <Heading size={1}>{data?.updateText}</Heading>
              <Link
                href={data?.recoverUserNameLink}
                text={data?.recoverUserNameLabel}
                showUnderline={true}
                htmlAttributes={{ target: "_blank" }}
              />
              <Link
                href={data?.resetPasswordLink}
                text={data?.resetPasswordLabel}
                showUnderline={true}
                htmlAttributes={{ target: "_blank" }}
              />
            </Styled.Trouble>
          </Styled.FieldsWrapper>
        </Form>
      )}
    </Formik>
  );
}

// @TODO; implement this later
/* This feature will exist on the future. For now needs more discussion */
/* <Styled.RememberMe>
                <Checkbox
                  value={""}
                  id={""}
                  name={"rememberMe"}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                >
                  Keep me signed in
                </Checkbox>
              </Styled.RememberMe> */
