import styled, {
  DefaultTheme,
  StyledComponentProps,
  css
} from "styled-components";

interface SignInWrapperProps
  extends StyledComponentProps<"div", DefaultTheme, {}, never> {
  appearance?: "primary" | "secondary";
  isError?: true | false;
}

export const SignInWrapper = styled.div<SignInWrapperProps>`
  background-color: ${(props: any) =>
    props.appearance === "primary"
      ? props.theme.color.monochrome1.default
      : props.theme.color.monochrome2.default};
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  /* Viewport media only Iphone SE */
  @media (max-width: 380px) {
    height: ${(props: any) =>
      props.isError ? "calc(100vh - 110px)" : "calc(100vh - 215px)"};
  }

  /* Viewport media only Surface Duo */
  @media (max-width: 540px) and (max-height: 720px) {
    height: ${(props: any) =>
      props.isError ? "calc(100vh - 160px)" : "calc(100vh - 220px)"};
  }

  /* Viewport media only Nest Hub */
  @media (max-width: 1024px) and (max-height: 600px) {
    /* height: ${(props: any) =>
      props.isError ? "100vh" : "calc(100vh - 70px)"}; */
    height: 100%;
  }

  @media (min-width: 1024px) and (min-height: 840px) {
    height: calc(100vh - 241px);
  }
`;

export const TextWrapper = styled.div(
  () => css`
    margin-bottom: 32px;
    h3 {
      font-size: 28px;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
    }
    width: 20em;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      margin-top: 24px;
      h3 {
        font-size: 20px;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
      }
    }
  `
);

export const ErrorMessageContainer = styled.div`
  background-color: ${(props: any) =>
    props.appearance === "primary"
      ? props.theme.color.monochrome2.default
      : props.theme.color.monochrome1.default};
  padding: 8px 28px;
  display: flex;
  flex-direction: column;
  border: 2px solid #bd0000;
  border-radius: 6px;
  margin-bottom: 36px;
  & > div:first-child {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 8px 26px;
    margin-bottom: 24px;
    & > div:first-child {
      font-size: 18px;
    }
  }
`;

export const Trouble = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  h2 {
    margin: 0;
  }
  a {
    margin: 2px 0;
    text-underline-offset: 4px;
  }

  a:hover {
    color: ${(props: any) => props.theme.color.monochrome3.default};
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    a {
      margin: 6px 0;
    }
  }
`;

export const RememberMe = styled.div`
  & > label {
    font-size: 16px;
  }
  margin: 38px 0 40px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin: 28px 0 26px;
    & > label {
      font-size: 14px;
    }
  }
`;

export const FieldsWrapper = styled.div`
  width: 20em;
  & input[type="password"] {
    -webkit-text-security: disc;
    margin: 8px 0;
  }
  & input[type="text"] {
    margin: 8px 0;
  }
  & input[type="email"] {
    margin: 8px 0;
  }
`;

export const ButtonContainer = styled.div`
  margin: 1em auto;
  button {
    width: 100%;
  }
`;
