import * as Yup from "yup";

import { errorMessages } from "../../../lib/utilities";

export const signInFormSchema = Yup.object().shape({
  user: Yup.string().required(errorMessages.invalidUsername),
  //TODO: remove comment for allowing validation after e2e
  // .test("user", errorMessages.invalidUsername, (value) =>
  //   /^[A-Za-z0-9]+$/i.test(value || "")
  // )
  password: Yup.string().required(errorMessages.invalidPassword)
});
