import { graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";
import { TextInputProps } from "@source-web/text-input";

import { IUser } from "../../context/auth/auth.types";
import { extractErrorCode } from "../../context/auth/helpers";
import { useAuth } from "../../context/auth/useAuth";
import { trackLogin } from "../../lib";
import { PageContext } from "../../types";
import { SignInForm } from "./signInForm";
import { SignInFormBox } from "./signInForm/signInFormBox";
import * as Styled from "./styles/signIn.styles";

export interface ContentfulSignInPage {
  contentfulSignInPage: {
    heading: string;
    description: string;
    buttonText: string;
    trouble: string;
    recoverUserNameLabel: string;
    recoverUserNameLink: string;
    resetPasswordLabel: string;
    resetPasswordLink: string;
    updateText: string;
    textFields: Array<{
      label: string;
      placeholder: string;
      fieldId: string;
      helpText: string;
      type: TextInputProps["type"];
    }>;
    errorMessage: Array<string>;
    errorMessages: Array<{
      code: string;
      reason: string;
    }>;
    signinPageLogo: Array<string>;
  };
}
interface SignInPage {
  data: ContentfulSignInPage;
  pageContext: PageContext;
}
export type FormikValuesType = Pick<IUser, "user" | "password">;

export const query = graphql`
  query GetSignInPageContent($locale: String) {
    contentfulSignInPage(node_locale: { eq: $locale }) {
      heading
      description
      buttonText
      recoverUserNameLabel
      recoverUserNameLink
      resetPasswordLabel
      resetPasswordLink
      textFields {
        label
        fieldId
        placeholder
        helpText
        type
      }
      updateText
      errorMessage
      errorMessages {
        code
        reason
      }
    }
  }
`;

type MiddlewareError = Error & {
  status: number;
};

function SignIn({
  data,
  pageContext: { formattedLocale, locale }
}: SignInPage) {
  const { contentfulSignInPage } = data;

  const theme = useTheme();
  const { onSignIn, authState } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { checkTimeExpirationAuthState } = useAuth();

  const dateNow = Date.now();

  function getErrorStatus(error: MiddlewareError) {
    const errorCode = extractErrorCode(error.message);
    const messageBasedOnErrorMessage = contentfulSignInPage.errorMessages.find(
      (item) => item.code === errorCode
    );

    if (messageBasedOnErrorMessage) {
      return messageBasedOnErrorMessage.reason ?? null;
    }

    const messageBasedOnErrorCode = contentfulSignInPage.errorMessages.find(
      (item) => item.code === String(error.status)
    );

    if (messageBasedOnErrorCode) return messageBasedOnErrorCode.reason;

    return (
      contentfulSignInPage.errorMessages.find(
        (item) => item.code === "unexpected"
      )?.reason ?? "Unexpected error"
    );
  }

  const { mutate, isLoading, isError } = useMutation<
    FormikValuesType,
    unknown,
    FormikValuesType,
    FormikValuesType
  >({
    //ts-ignoring it, apparently there's a bug under
    // react-query types and Context functions.
    //@ts-ignore
    mutationFn: (values: IUser) => onSignIn(values),
    onSuccess: () => {
      // on Success, we track the user sing in
      trackLogin({ page_locale: locale });
    },
    onSettled: () => {
      checkTimeExpirationAuthState(dateNow);
    },
    onError: (error: MiddlewareError) => {
      // on error, we extract it from the message.
      const errorReason = getErrorStatus(error);

      setErrorMessage(errorReason);
    },
    mutationKey: "signIn"
  });

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate(`/${formattedLocale}/dashboard`);
    }
  });

  const formForLanguage = {
    it: <SignInFormBox />,
    en: (
      <SignInForm
        data={data.contentfulSignInPage}
        isError={isError}
        isLoading={isLoading}
        hideErrorMessage={() => setErrorMessage(null)}
        onSignIn={mutate}
        errorMessage={errorMessage}
      />
    )
  }[process.env.GATSBY_DEFAULT_LANGUAGE as string];

  return (
    <Styled.SignInWrapper isError={isError}>
      <Styled.TextWrapper>
        <Heading appearance="primary" inverse={theme.name !== "WS10"} level={3}>
          {contentfulSignInPage?.heading}
        </Heading>

        {!isError && (
          <Paragraph
            appearance="primary"
            inverse={theme.name !== "WS10"}
            size={2}
          >
            {contentfulSignInPage?.description}
          </Paragraph>
        )}
      </Styled.TextWrapper>
      {formForLanguage}
    </Styled.SignInWrapper>
  );
}

export default SignIn;
